(function ($) 
{
    "use strict";
    $.fn.angularFunction = function () 
    { 
        console.log('custom js call');  
      
        $(document).on("click",".nav li a",function() {
            var value = $(this).attr('data-filter');
            if(value == "all")
            {
                $('.notification_box').show();
            }
            else
            {
                $(".notification_box").not('.'+value).hide();
                $('.notification_box').filter('.'+value).show();
            }
            if ($(".nav li a").removeClass("active")) {
                $(this).removeClass("active");
                }
                $(this).addClass("active");
        });
    }


    
})
$(document).on("click","#nav a.menu-icon",function(e) 
    {
        e.preventDefault();
        $("#nav ul").slideToggle(300);
    });


    if($(window).width() <= 768)
    {
        $(document).on("click","#nav ul li a",function(e)
        {          
            $("#nav ul").fadeOut(300);
        });
    }
    
//HLCZXQUD9Y9T4  - kajal
//5F49VSR5Q8GFS - ND dev
//WBXNYPKQ3DUXC - Live

//sandbox / production
function callPay() {
    PayPal.Donation.Button({
        env: 'sandbox',
        hosted_button_id: '5F49VSR5Q8GFS',
        // business: 'YOUR_LIVE_EMAIL_OR_PAYERID',
        image: {
            src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
            title: 'PayPal - The safer, easier way to pay online!',
            alt: 'Donate with PayPal button'
        },
        onComplete: function (params) {
            console.log("onComplete>>>>", params);
            alert("hello")
            setTimeout(() => {
                console.log("Innn >>>>>>")
                  dismiss();
              }, 4000);
        },
        onCancel:function(param){
            console.log("onCancel>>>>", param);
            alert("hello")
          },
          onError:function(param){
            console.log("onError>>>>", param);
            alert("hello")
          },
        onclose:function(param){
            console.log("onclose>>>>", param);
            alert("hello")
        },
    }).render('#paypal-donate-button-container');
}

console.log('custom.jsss');
$('.select2').select2({});



$(document).on("click","#view_nav li a",function() 
{
    var openlist = $(this).attr('data-id');
    $('#view_list .list_views').hide();
    $(openlist).show();
    $('#view_nav li a').removeClass('active');
    $(this).addClass('active');

    $('be-orgchart').find('g.chart').attr('transform', 'translate(600,20) scale(0.7)');

    $('#myUL li ul').removeClass('active');
    $('#myUL li .list_content span.caret').removeClass('caret-down');  

    $('#myUL li:first-child > ul').addClass('active');
    $('#myUL li:first-child > .list_content span.caret').addClass('caret-down');         
});


$(document).on("click","#myUL .list_content .caret",function() 
{
    $(this).toggleClass('caret-down');
    $(this).parent().parent().find('.nested').toggleClass('active');
});